import React from 'react';
import {Button} from "antd";
import {useTranslation} from "react-i18next";

interface Props {
    showDetailsButton?: boolean
}

const NotificationBanner = ({showDetailsButton}: Props) => {

    const {t} = useTranslation();

    return (
        <div className={"full-width p-20 white-bg flex flex-align-items-center"} style={{borderBottom: "2px solid #dd1541"}}>
            <div className={"flex-grow-1"}>
                <div>
                    <span className={"bold larger-text"}>{t('Server update')}</span>
                </div>
                <div className={"flex flex-col"}>
                    <span>{t("Server update_details")}</span>
                </div>
            </div>
            {
                showDetailsButton &&
                <div>
                    <Button type={"primary"}>{t('Details')}</Button>
                </div>
            }

        </div>
    );
}

export default NotificationBanner;
